











































import {
  defineComponent,
  onMounted,
  ref,
  useContext,
} from '@nuxtjs/composition-api';

import HomeCarousel from '~/components/HomeCarousel.vue';
import PromotionActivities from '~/components/Promotion/PromotionActivities.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useApi } from '~/composables/useApi';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useI18n } from '~/helpers/hooks/usei18n';
import { CmsPage } from '~/modules/GraphQL/types';
import getHomeDataGql from '~/queries/getHomeData.gql';
import getSliderGql from '~/queries/getSlider.gql';
import { Promotion, SliderItem } from '~/queries/types';

export default defineComponent({
  name: 'HomePage',
  components: {
    HomeCarousel,
    LoadWhenVisible,
    PromotionActivities,

    CodeOfTheWeekCarousel: () =>
      import(
        /* webpackPrefetch: true */ '~/components/Promotion/CodeOfTheWeekCarousel.vue'
      ),
    NewProducts: () =>
      import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
    // ReducedProducts: () =>
    //   import(/* webpackPrefetch: true */ '~/components/ReducedProducts.vue'),
    FeaturedProducts: () =>
      import(/* webpackPrefetch: true */ '~/components/FeaturedProducts.vue'),
    ShopByCategory: () =>
      import(/* webpackPrefetch: true */ '~/components/ShopByCategory.vue'),
  },
  setup() {
    const { query } = useApi();
    const {
      $config: { currentSite },
    } = useContext();
    const page = ref<CmsPage | null>(null);
    const sliders = ref<SliderItem[] | Array<any>>([]);
    const codeSliders = ref<Promotion | any>({ name: '', id: 0, banners: [] });
    const promotions = ref<Promotion>({ name: '', banners: [], id: 0 });
    const newProducts = ref<[] | Array<any>>([]);
    const { locale } = useI18n();

    // sliderid us 11 : 1,
    const sliderId = locale === 'gb' || currentSite === 'cn' ? 1 : 11;
    const codeId = locale === 'gb' || currentSite === 'cn' ? 2 : 12;
    const promtionId = locale === 'gb' || currentSite === 'cn' ? 3 : 13;

    const fetchHomeSlidersData = async () => {
      console.log('----- fetchHomeSlidersData --', sliderId);
      if (!sliders.value || sliders.value.length === 0) {
        const { data } = await query<any>(getSliderGql, {
          sliderId,
        });
        sliders.value = data?.slidersData?.banners;
      }
    };
    const fetchHomeData = async () => {
      if (
        codeSliders.value.banners.length === 0 ||
        promotions.value.banners.length === 0
      ) {
        const { data } = await query<any>(getHomeDataGql, {
          codeId,
          promtionId,
        });
        codeSliders.value = data?.codeData;
        promotions.value = data?.promtionData;
      }
    };
    const handlenewProducts = (items) => {
      newProducts.value = items;
    };

    onMounted(async () => {
      await fetchHomeSlidersData();
      await fetchHomeData();
      page.value = {
        meta_description:
          'Unineed is an UK online fashion and beauty retailer offering products from over 450 brands ranging from skincare to make-up to accessories. Your one-stop shop for all of your beauty and skincare needs.',
        meta_keywords:
          'Beauty,Skincare,Haircare, Make Up, Perfume,Handbags, Watches,Sunglasses, Shaving &amp; Dental Care.',
        meta_title: 'Luxury Beauty, Skincare, Haircare &amp; Makeup | Unineed',
        title: 'Home page',
      } as CmsPage;
      // addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    // @ts-ignore
    return {
      page,
      sliders,
      codeSliders,
      promotions,
      locale,
      currentSite,
      handlenewProducts,
      newProducts,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
